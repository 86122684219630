import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocationService } from './location.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-co-location',
  templateUrl: './co-location.component.html',
  styleUrls: ['./co-location.component.scss']
})
export class CoLocationComponent implements OnInit, OnDestroy {
  @ViewChild('mapElement', { static: true }) mapElement!: ElementRef;
  @Input() latitude:any;
  @Input() longitude:any;
  private langChangeSubscription: Subscription | null = null;
  addressEnglish: string | null = null;
  addressArabic: string | null = null;
  address: string | null = null;
  zoom: number = 15;
  map: google.maps.Map | undefined;
  constructor(private geocodingService: LocationService,private http: HttpClient,public translateService: TranslateService) {}

  ngOnInit(): void {
      // Listen for language change
      this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
        this.getAddressess();
      });
  
      // Initial address fetch
    this.getAddressess();
  }
  getAppLanguage(): string {
    return this.translateService.currentLang || 'en'; // Fallback to 'en' if no language is set
  }
  getAddressess() {
    console.log(this.latitude, this.longitude);
    
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.latitude}&lon=${this.longitude}&accept-language=${this.translateService.currentLang}`;
    this.http.get(url).subscribe(
      (response: any) => {
        console.log(response);
        this.address = response.display_name;
        this.initializeMap()
        console.log('language',this.translateService.currentLang);
      },
      (error) => {
        console.error('Error fetching address:', error);
      }
    );
  }

  initializeMap(): void {
    if (!this.latitude || !this.longitude) {
      console.error('Invalid coordinates:', this.latitude, this.longitude);
      return;
    }
  
    const lat = parseFloat(this.latitude);
    const lng = parseFloat(this.longitude);
  
    const mapOptions: google.maps.MapOptions = {
      center: { lat, lng },
      zoom: 15, // Set zoom level here
    };
  
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  
    // Explicitly set zoom after initialization
    this.map.setZoom(20);
  
    // Ensure zoom level is applied after the map loads
    this.map.addListener('idle', () => {
      this.map?.setZoom(20);
    });
  
    console.log('Map initialized with zoom level:', this.map.getZoom());
  }
  
  // getAddresses(): void {
  //   this.geocodingService
  //     .getAddressFromCoordinates(this.latitude, this.longitude, 'en')
  //     .subscribe(
  //       (response) => {
  //         if (response.results && response.results.length > 0) {
  //           this.addressEnglish = response.results[0].formatted_address;
  //         } else {
  //           this.addressEnglish = 'Address not found';
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching English address:', error);
  //         this.addressEnglish = 'Error fetching address';
  //       }
  //     );

  //   this.geocodingService
  //     .getAddressFromCoordinates(this.latitude, this.longitude, 'ar')
  //     .subscribe(
  //       (response) => {
  //         if (response.results && response.results.length > 0) {
  //           this.addressArabic = response.results[0].formatted_address;
  //         } else {
  //           this.addressArabic = 'العنوان غير موجود';
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching Arabic address:', error);
  //         this.addressArabic = 'خطأ في جلب العنوان';
  //       }
  //     );
      
  // }
  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
