import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ServiceRequest } from 'src/app/services/api/admin/compound-services.service';
interface Column {
  key: string;
  header: string;
  sortable?: boolean;
  cell?: (element: any, index: number) => string | number;
}

export interface Action {
  label: string;  // Label for the action
  icon: string;   // Icon for the action (FontAwesome, Material, etc.)
  handler: (element: any) => void;  // Function to handle the action
}

@Component({
  selector: 'app-co-table',
  templateUrl: './co-table.component.html',
  styleUrls: ['./co-table.component.scss'],
})
export class CoTableComponent implements OnInit, AfterViewInit {
  @Input() dataSource!: MatTableDataSource<any>;
  @Input() columns: Column[] = [];
  @Input() tableActions: Action[] = [];
  @Input() noDataMessage = 'No data available';
  @Input() sortActive = '';
  @Input() sortDirection: 'asc' | 'desc' = 'asc';

  @Output() rowClick = new EventEmitter<any>();
  displayedColumns: string[] = [];
  @ViewChild(MatSort) sort!: MatSort;
  subs = new Subscription();
  constructor(private cdr: ChangeDetectorRef) { }
  ngOnInit(): void {
    // Add dynamic columns to displayedColumns
    this.displayedColumns = this.columns.map(column => column.key);

    // Add actions column if actions are provided
    if (this.tableActions && this.tableActions.length > 0) {
      this.displayedColumns.push('actions');
    }
  }
  @Output() sortChange = new EventEmitter<{ active: string; direction: string }>();
  ngAfterViewInit() {
    this.sort.sortChange.subscribe((result: any) => {
      this.sortChange.emit({ active: result.active, direction: result.direction });
    });
  }
  // This method will emit the sorting information to the parent component
  onSortChange(result: any) {
    // Emit the sorting change to the parent component
    this.sortChange.emit({
      active: result.active,
      direction: result.direction
    });
  }
 
}
