<div class="modal">
  <div class="modal-content">
    <div
      class="d-flex flex-column align-items-start justify-content-start px-3 pb-3"
    >
      <p class="modal-title mb-3">{{ title | translate }}</p>
      <p class="modal-subtitle">{{ subTitle | translate }}</p>
    </div>
    <div class="buttons-container d-flex w-100 p-3">
      <!-- [class]="markDone ? 'success-bg' : 'dangerous-bg'" -->
      <button
        (click)="onConfirm()"
        mat-raised-button
        class="text-white w-100"
        class="bg-primary text-white w-100"
      >
        {{ "ACTIONSBUTTONS.CONFIRM" | translate }}
      </button>
      <!-- class="cancel-btn w-100" -->

      <button
        (click)="onCancel()"
        mat-raised-button
        class="bg-dark text-white w-100"
      >
        {{ "ACTIONSBUTTONS.CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
