<div class="modal" *ngIf="isOpen && imgList.length > 0" (click)="closePopup()">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div>
            <span class="close-button" (click)="closePopup()">&times;</span>
        </div>
        <div class="image-container">
            <button *ngIf="imgList.length > 1" class="nav-button left" (click)="previousImage()">

                <mat-icon>chevron_left</mat-icon>

            </button>
            <img [src]="imageUrl" alt="Popup Image" class="modal-image" />
            <button *ngIf="imgList.length > 1" class="nav-button right" (click)="nextImage()">

                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
        <div class="image-list" *ngIf="imgList.length > 1">
            <div class="image-item" *ngFor="let image of imgList; let i = index" [class.active]="i === currentIndex">
                <img [src]="image" (click)="openImage(image)" alt="attachment" class="list-image" />
            </div>
        </div>
    </div>
</div>