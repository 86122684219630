import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private apiKey = 'AIzaSyB2cuIOUgqEcgXi1KvkXVr7mNa3ym4h7dA'; // Replace with your API Key
  private geocodingUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

  constructor(private http: HttpClient) {}

  getAddressFromCoordinates(lat: number, lng: number, language: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.apiKey}&language=${language}`;
    return this.http.get<any>(url);
  }
}
