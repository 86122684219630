import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-co-icon',
  templateUrl: './co-icon.component.html',
  styleUrls: ['./co-icon.component.scss']
})
export class CoIconComponent {
  @Input() icon!: string;
  @Input() tableActions?: Array<{ icon: string; label: string; handler: (element: any) => void }>;
  @Input() element: any;
}
