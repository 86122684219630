<button mat-icon-button [matMenuTriggerFor]="actionsMenu" aria-label="More actions">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-icon *ngIf="!tableActions">{{ icon }}</mat-icon>

<mat-menu #actionsMenu="matMenu">
    <button mat-menu-item *ngFor="let action of tableActions" (click)="action.handler(element)">
        <mat-icon>{{ action.icon }}</mat-icon>
        <span>{{ action.label | translate }}</span>
    </button>
</mat-menu>