<div class="col-12 d-flex justify-content-start">
  <div class="col-12 justify-content-start">
    <mat-paginator
      class="paginat w-100"
      *ngIf="length"
      style-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 20, 50, 100, 200]"
      [pageIndex]="pageIndex"
      (page)="handlePageChange($event)"
    >
    </mat-paginator>
  </div>
</div>
