<div class="dynamic-table">
    <table mat-table [dataSource]="dataSource" class="Header" matSort [matSortActive]="sortActive"
        (matSortChange)="onSortChange($event)" [matSortDirection]="sortDirection" [matSortDisableClear]="true">
        <!-- Dynamic Columns -->
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable">
                {{ column.header | translate }}
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
                <ng-container *ngIf="column.cell; else defaultCell">
                    {{ column.cell(element, i) }}
                </ng-container>
                <ng-template #defaultCell>
                    {{ element[column.key] }}
                </ng-template>
            </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <app-co-icon [icon]="'more_vert'" [tableActions]="tableActions" [element]="element"></app-co-icon>
            </td>
        </ng-container>

        <!-- Header and Rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="columns.length + 1" style="text-align: center">
                {{ noDataMessage | translate }}
            </td>
        </tr>
    </table>
</div>